import { useState, useRef } from 'react';
import JSZip from 'jszip';

const useFileInput = ({ maxFileSizeMb, fileType }) => {
  const [file, setFile] = useState({});
  const [fileURL, setFileURL] = useState('');
  const [fileError, setFileError] = useState('');
  const fileInputRef = useRef(null);

  const validateInteractiveFile = async (selectedFile, indexName) => {
    const zipFile = await JSZip.loadAsync(selectedFile);
    const zipEntries = Object.keys(zipFile.files);
    const directories = [];
    const zipFiles = [];

    zipEntries.forEach((entry) => {
      if (!entry.startsWith('__MACOSX')) {
        if (!directories.includes(entry.split('/')[0]))
          directories.push(entry.split('/')[0]);
        zipFiles.push(entry.split('/')[1]);
      }
    });

    if (directories.length !== 1) {
      setFileError('The zip file should contain only one main folder.');
      return false;
    }

    const rootDir = directories[0];

    if (!zipFiles.includes(indexName)) {
      setFileError(
        `The specified index file "${indexName}" was not found in the "${rootDir}" folder inside the zip file.`
      );
      return false;
    }

    const zipFileName = file.name.replace(/\.zip$/i, '');
    if (zipFileName !== rootDir) {
      setFileError(
        `The zip file name "${zipFileName}" and the folder name "${rootDir}" inside the zip file do not match. They need to be the identical.`
      );
      return false;
    }

    return true;
  };

  const setErrorMessage = (error) => {
    setFileError(error);

    setTimeout(() => {
      setFileError('');
    }, 3000);

    setFile({});
  };

  const handleAttachFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveSelectedFile = () => {
    setFile({});
  };

  const handleFileInputChange = (event) => {
    const targetFile = event.target.files[0];

    if (targetFile) {
      const fileSizeInMB = targetFile.size / (1024 * 1024);

      if (fileSizeInMB > maxFileSizeMb) {
        setErrorMessage(
          `File size exceeds ${maxFileSizeMb} MB limit. Please select a smaller file.`
        );
      } else if (!targetFile.type.startsWith(fileType)) {
        setErrorMessage(`Please select an ${fileType} file.`);
      } else {
        const reader = new FileReader();

        reader.onload = (e) => {
          setFile(targetFile);
          setFileURL(e.target.result);
        };

        reader.readAsDataURL(targetFile);
        event.target.value = null;
      }
    }
  };

  return {
    file,
    fileURL,
    fileError,
    fileInputRef,
    setFile,
    setFileError,
    handleAttachFile,
    handleRemoveSelectedFile,
    handleFileInputChange,
    validateInteractiveFile,
  };
};

export default useFileInput;
