import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Box,
  Divider,
  Textarea,
  Input,
  VStack,
  HStack,
  RadioGroup,
  Radio,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Progress,
} from '@chakra-ui/react';

import { MdVideoSettings } from 'react-icons/md';
import SubQuestForm from 'components/subQuest/SubQuestForm';
import FormikFormControl from 'components/common/forms/FormikFormControl';

import useFileInput from 'hooks/useFileInput';
import { AttachmentIcon, CloseIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';

import { createInteractiveContentSubQuest } from 'store/modules/subquest/subquest-action';
import { subquestActions } from 'store/modules/subquest/subquest-slice';
import { arrayOfString } from 'utils/jsonParser';

function CreateInteractiveContentMd({ quest }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const [triggerSubQuestForm, setTriggerSubQuestForm] = useState(0);
  const [isSubQuestFormValid, setIsSubQuestFormValid] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [subQuestFormInputs, setSubQuestFormInputs] = useState({
    name: '',
    objective: '',
    code: '',
    isPublished: '',
    tags: [],
    groups: [],
  });

  const [isRequirePass, setIsRequirePass] = useState('true');

  const [formInputs, setFormInputs] = useState({
    transcript: '',
    indexName: 'index_lms.html',
  });

  const createInteractiveContent = useSelector(
    (state) => state.subquest.createInteractiveContent
  );

  const uploadProgress = useSelector((state) => state.subquest.uploadProgress);

  const formikForm = useRef();

  const {
    file,
    fileError,
    fileInputRef,
    setFileError,
    handleAttachFile,
    handleRemoveSelectedFile,
    handleFileInputChange,
    validateInteractiveFile,
  } = useFileInput({ maxFileSizeMb: 850, fileType: 'application/' });

  useEffect(() => {
    if (file.name) setFileError('');
  }, [file, setFileError]);

  useEffect(() => {
    if (quest.ownerGroups) {
      setSubQuestFormInputs((prev) => ({
        ...prev,
        groups: arrayOfString(quest.ownerGroups),
      }));
    }
  }, [quest.ownerGroups]);

  const formValidation = Yup.object({
    transcript: Yup.string().min(3).required('Required'),
    indexName: Yup.string().min(1).max(20).required('Required'),
  });

  const handleIsRequirePassChange = (e) => {
    setIsRequirePass(e);
  };

  const handleTriggerSubmit = () => {
    setTriggerSubQuestForm((trigger) => trigger + 1);
    if (!file.name) setFileError('Interactive Content is required');
    setIsFormSubmit(true);
  };

  const handleSubmitForm = async (values) => {
    if (isSubQuestFormValid === false || !file.name) return;

    setFormInputs({ ...values });

    if (file.name) {
      const isFileValid = await validateInteractiveFile(
        file,
        formInputs.indexName
      );
      if (!isFileValid) return;
    }

    setFileError('');

    const formData = new FormData();

    Object.entries(subQuestFormInputs).forEach(([key, value]) => {
      if (key !== 'tags' && key !== 'groups') formData.append(key, value);
    });

    if (subQuestFormInputs.tags.length > 0)
      subQuestFormInputs.tags.forEach((tag) => formData.append('Tags', tag));
    if (subQuestFormInputs.groups.length > 0)
      subQuestFormInputs.groups.forEach((group) =>
        formData.append('OwnerGroups', group)
      );

    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append('IsRequirePass', isRequirePass);
    formData.append('ZipFile', file, file.name);

    dispatch(createInteractiveContentSubQuest({ id: quest.id, formData }));
  };

  useEffect(() => {
    if (isFormSubmit && isSubQuestFormValid) {
      formikForm?.current?.submitForm();
      setIsFormSubmit(false);
    }
  }, [isFormSubmit, isSubQuestFormValid, setIsFormSubmit]);

  const handleResult = () => {
    if (createInteractiveContent.status === ReducerStatus.SUCCEEDED) {
      setFormInputs({
        transcript: '',
        indexName: 'index_lms.html',
      });

      setSubQuestFormInputs({
        name: '',
        objective: '',
        code: '',
        isPublished: '',
        tags: [],
        emails: [],
      });

      setIsRequirePass('true');

      setTriggerSubQuestForm(-1);
      handleRemoveSelectedFile();
      onClose();
    }
    dispatch(subquestActions.resetCreateInteractiveContent());
  };

  return (
    <Box>
      {createInteractiveContent.status === ReducerStatus.SUCCEEDED ||
      createInteractiveContent.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={isOpen}
          onSubmit={handleResult}
          isError={createInteractiveContent.status === ReducerStatus.FAILED}
          title={
            createInteractiveContent.status === ReducerStatus.SUCCEEDED
              ? 'Interactive Content Sub-Quest Created'
              : null
          }
          message={
            createInteractiveContent.status === ReducerStatus.SUCCEEDED
              ? 'The interactive content sub-quest has been created successfully. Please allow a few minutes for the system to unzip the attached files.'
              : createInteractiveContent.error?.data?.title || 'Server Error'
          }
          errorObject={createInteractiveContent.error?.data?.errors}
        />
      ) : (
        <>
          <Button
            onClick={onOpen}
            leftIcon={<MdVideoSettings />}
            w="100%"
            colorScheme="red"
            size="lg"
          >
            Interactive Content
          </Button>
          <Modal
            size="2xl"
            closeOnOverlayClick={
              createInteractiveContent.status !== ReducerStatus.LOADING
            }
            isOpen={isOpen}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Create Interactive Content Sub-Quest</ModalHeader>
              <ModalCloseButton
                disabled={
                  createInteractiveContent.status === ReducerStatus.LOADING
                }
              />
              <ModalBody my={3}>
                <SubQuestForm
                  questName={quest.name}
                  groups={quest.ownerGroups}
                  subQuestFormInputs={subQuestFormInputs}
                  triggerSubQuestForm={triggerSubQuestForm}
                  setSubQuestFormInputs={setSubQuestFormInputs}
                  setIsSubQuestFormValid={setIsSubQuestFormValid}
                />
              </ModalBody>
              <Formik
                enableReinitialize
                innerRef={formikForm}
                initialValues={formInputs}
                onSubmit={(values) => {
                  handleSubmitForm(values);
                }}
                validationSchema={formValidation}
              >
                {() => (
                  <Box>
                    <Form>
                      <ModalBody>
                        <Divider mb={4} />

                        <VStack spacing={6} align="stretch">
                          <FormControl isInvalid={fileError !== ''}>
                            {file?.name && (
                              <Box my={4}>
                                <Flex
                                  gap={2}
                                  my={2}
                                  justify="center"
                                  align="center"
                                >
                                  <Text flex={1}>
                                    Selected File: {file?.name}
                                  </Text>
                                  <Button
                                    type="button"
                                    colorScheme="teal"
                                    variant="ghost"
                                    onClick={handleRemoveSelectedFile}
                                  >
                                    <CloseIcon />
                                  </Button>
                                </Flex>
                              </Box>
                            )}

                            <Text fontSize="sm" as="b">
                              Ensure that the attached zip file contains only
                              one folder when opened (excluding the default
                              _MACOSX folder on macOS)
                            </Text>

                            <Box
                              as="button"
                              type="button"
                              borderRadius="md"
                              bg="teal"
                              w="100%"
                              mt={5}
                              px={4}
                              h={10}
                              color="white"
                              onClick={handleAttachFile}
                            >
                              <input
                                type="file"
                                hidden
                                accept="application/zip"
                                ref={fileInputRef}
                                onChange={handleFileInputChange}
                              />
                              <AttachmentIcon mx={2} />
                              Attach Interactive Content File
                            </Box>
                            {fileError !== '' && (
                              <FormErrorMessage>{fileError}</FormErrorMessage>
                            )}
                            <FormHelperText>
                              Please attach the interactive content file in a
                              compressed state (.zip).
                            </FormHelperText>
                          </FormControl>

                          <Field name="indexName">
                            {({ field, form }) => (
                              <FormikFormControl
                                label="Index Name"
                                helperText="Please specify the root path .html of the zipped file for the interactive content. Typically, the root path is index_lms.html."
                                errors={form.errors.indexName}
                                touched={form.touched.indexName}
                              >
                                <Input {...field} />
                              </FormikFormControl>
                            )}
                          </Field>

                          <FormikFormControl
                            label="Require Pass"
                            helperText="Set this to true to require users to pass the material to mark the activity as completed. Set it to false if passing is not required."
                          >
                            <RadioGroup
                              value={isRequirePass}
                              onChange={handleIsRequirePassChange}
                            >
                              <HStack spacing={5}>
                                <Radio colorScheme="red" value="true">
                                  True
                                </Radio>
                                <Radio colorScheme="blue" value="false">
                                  False
                                </Radio>
                              </HStack>
                            </RadioGroup>
                          </FormikFormControl>

                          <Field name="transcript">
                            {({ field, form }) => (
                              <FormikFormControl
                                label="Transcript"
                                helperText="If the content is a video or article, please provide its transcript. The transcript will help train the AI Companion to answer trainee questions about the content. If the content is not a video and does not include a quiz section, please type 'N/A'."
                                errors={form.errors.transcript}
                                touched={form.touched.transcript}
                              >
                                <Textarea {...field} />
                              </FormikFormControl>
                            )}
                          </Field>

                          {createInteractiveContent.status ===
                            ReducerStatus.LOADING && (
                            <Progress
                              hasStripe
                              isAnimated
                              value={uploadProgress}
                            />
                          )}
                        </VStack>
                      </ModalBody>

                      <ModalFooter>
                        <Button
                          variant="outline"
                          colorScheme="blue"
                          mr={3}
                          onClick={onClose}
                          isLoading={
                            createInteractiveContent.status ===
                            ReducerStatus.LOADING
                          }
                        >
                          Close
                        </Button>
                        <Button
                          isLoading={
                            createInteractiveContent.status ===
                            ReducerStatus.LOADING
                          }
                          onClick={handleTriggerSubmit}
                          colorScheme="red"
                          px={10}
                        >
                          Submit
                        </Button>
                      </ModalFooter>
                    </Form>
                  </Box>
                )}
              </Formik>
            </ModalContent>
          </Modal>
        </>
      )}
    </Box>
  );
}

CreateInteractiveContentMd.propTypes = {
  quest: PropTypes.object.isRequired,
};

export default CreateInteractiveContentMd;
