import { createSlice } from '@reduxjs/toolkit';
import { ReducerStatus, CompanionMode } from 'utils/constants';
import {
  promptSuggestion,
  promptVideoCompanion,
  promptCourseOutlineGenerator,
  promptQuizGenerator,
  promptQuizGeneratorConvert,
} from './companion-action';

const companionSlice = createSlice({
  name: 'companion',
  initialState: {
    modal: false,
    error: null,
    status: ReducerStatus.IDLE,
    mode: CompanionMode.COURSE_SUGGESTION,
    greetings: CompanionMode.DEFAULT_GREETINGS,
    context: null,
    conversations: [],
    outlines: [],
    initialPrompt: false,
    convertQuizToJson: false,
    quizJson: null,
    isDisabled: false,
  },
  reducers: {
    addConversation(state, action) {
      state.conversations = [
        ...state.conversations,
        {
          ...action.payload,
          timeSent: new Date().toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }),
        },
      ];
    },
    setOutlines(state, action) {
      state.outlines = action.payload;
    },
    setModal(state, action) {
      state.modal = action.payload;
    },
    setContext(state, action) {
      state.context = action.payload;
    },
    setInitialPrompt(state, action) {
      state.initialPrompt = action.payload;
    },
    setConvertQuizToJson(state, action) {
      state.convertQuizToJson = action.payload;
    },
    setMode(state, action) {
      state.mode = action.payload;
    },
    setGreetings(state, action) {
      state.greetings = action.payload;
    },
    setConversations(state, action) {
      state.conversations = action.payload;
    },
    setIsDisabled(state, action) {
      state.isDisabled = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(promptSuggestion.pending, (state) => {
        state.status = ReducerStatus.LOADING;
      })
      .addCase(promptSuggestion.fulfilled, (state, action) => {
        state.status = ReducerStatus.SUCCEEDED;
        state.conversations = [...state.conversations, action.payload];
      })
      .addCase(promptVideoCompanion.rejected, (state) => {
        state.status = ReducerStatus.FAILED;
        state.error = 'Something went wrong with the request.';
      })
      .addCase(promptVideoCompanion.pending, (state) => {
        state.status = ReducerStatus.LOADING;
      })
      .addCase(promptVideoCompanion.fulfilled, (state, action) => {
        state.status = ReducerStatus.SUCCEEDED;
        state.conversations = [...state.conversations, action.payload];
      })
      .addCase(promptSuggestion.rejected, (state) => {
        state.status = ReducerStatus.FAILED;
        state.error = 'Something went wrong with the request.';
      })
      .addCase(promptCourseOutlineGenerator.pending, (state) => {
        state.status = ReducerStatus.LOADING;
      })
      .addCase(promptCourseOutlineGenerator.fulfilled, (state, action) => {
        state.status = ReducerStatus.SUCCEEDED;
        state.conversations = [...state.conversations, action.payload];
      })
      .addCase(promptCourseOutlineGenerator.rejected, (state) => {
        state.status = ReducerStatus.FAILED;
        state.error = 'Something went wrong with the request.';
      })
      .addCase(promptQuizGenerator.pending, (state) => {
        state.status = ReducerStatus.LOADING;
      })
      .addCase(promptQuizGenerator.fulfilled, (state, action) => {
        state.status = ReducerStatus.SUCCEEDED;
        state.conversations = [...state.conversations, action.payload];
      })
      .addCase(promptQuizGenerator.rejected, (state) => {
        state.status = ReducerStatus.FAILED;
        state.error = 'Something went wrong with the request.';
      })
      .addCase(promptQuizGeneratorConvert.pending, (state) => {
        state.status = ReducerStatus.LOADING;
      })
      .addCase(promptQuizGeneratorConvert.fulfilled, (state, action) => {
        state.status = ReducerStatus.SUCCEEDED;
        state.quizJson = JSON.parse(action.payload);
      })
      .addCase(promptQuizGeneratorConvert.rejected, (state) => {
        state.status = ReducerStatus.FAILED;
        state.error = 'Something went wrong with the request.';
      });
  },
});

export const companionActions = companionSlice.actions;

export default companionSlice;
