import { useDispatch, useSelector } from 'react-redux';

import { CloseIcon } from '@chakra-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import {
  Stack,
  Button,
  Image,
  Flex,
  Center,
  Text,
  Box,
  Textarea,
  Drawer,
  DrawerBody,
  InputGroup,
  DrawerHeader,
  Divider,
  DrawerOverlay,
  DrawerContent,
  IconButton,
  Progress,
  useColorModeValue,
} from '@chakra-ui/react';
import { useRef, useState, useEffect } from 'react';
import { MdDelete, MdSend } from 'react-icons/md';

import { ReducerStatus, CompanionMode } from 'utils/constants';
import { useLocation } from 'react-router-dom';
import { companionActions } from './store/companion-slice';
import {
  promptCourseOutlineGenerator,
  promptSuggestion,
  promptVideoCompanion,
  promptQuizGenerator,
  promptQuizGeneratorConvert,
} from './store/companion-action';

import ChatItem from './components/ChatItem';

function Companion() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [message, setMessage] = useState('');
  const [activeConversations, setActiveConversations] = useState([]);

  const modal = useSelector((state) => state.companion.modal);
  const conversations = useSelector((state) => state.companion.conversations);
  const context = useSelector((state) => state.companion.context);
  const status = useSelector((state) => state.companion.status);
  const mode = useSelector((state) => state.companion.mode);
  const error = useSelector((state) => state.companion.error);
  const greetings = useSelector((state) => state.companion.greetings);
  const initialPrompt = useSelector((state) => state.companion.initialPrompt);
  const isDisabled = useSelector((state) => state.companion.isDisabled);
  const convertQuizToJson = useSelector(
    (state) => state.companion.convertQuizToJson
  );
  const listRef = useRef(null);
  const btnRef = useRef(null);

  useEffect(() => {
    const storedConversations = JSON.parse(
      localStorage.getItem('conversations')
    );

    if (storedConversations) {
      dispatch(
        companionActions.setConversations(storedConversations.slice(-10))
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (initialPrompt) {
      dispatch(companionActions.setInitialPrompt(false));
      dispatch(
        companionActions.setGreetings(
          'I am your AI Companion Bot, ready to assist you in generating quizzes from the selected reference.'
        )
      );
      dispatch(
        promptQuizGenerator({
          conversations: [],
          newMessage: `Generate questions`,
          context,
        })
      );
    }
  }, [mode, context, conversations, initialPrompt, dispatch]);

  useEffect(() => {
    if (convertQuizToJson) {
      dispatch(
        promptQuizGeneratorConvert({
          conversations: conversations.filter((convo) => convo.type === mode),
          newMessage: 'Convert To JSON',
        })
      );
      dispatch(companionActions.setConvertQuizToJson(false));
    }
  }, [mode, conversations, convertQuizToJson, dispatch]);

  const scrollToBottom = () => {
    if (listRef.current) {
      const container = listRef.current;
      container.scrollTop = container.scrollHeight;
      container.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth',
      });
    }
  };
  useEffect(() => {
    scrollToBottom();
    if (conversations.length > 0)
      localStorage.setItem('conversations', JSON.stringify(conversations));

    setActiveConversations(
      conversations.filter(
        (convo) => convo.type === mode && convo.context === context
      )
    );
  }, [conversations, context, mode, dispatch]);

  useEffect(() => {
    if (modal) setTimeout(scrollToBottom, 500);
  }, [modal]);

  useEffect(() => {
    scrollToBottom();
  }, [activeConversations]);

  useEffect(() => {
    dispatch(companionActions.setIsDisabled(false));

    const subQuestCompMatch = location.pathname.match(
      /^\/courses\/(\d+)\/quests\/(\d+)\/sub-quests\/(\d+)/
    );

    const courseGenMatch = location.pathname.match(/^\/admin\/courses\/(\d)/);

    if (!subQuestCompMatch && !courseGenMatch) {
      dispatch(companionActions.setMode(CompanionMode.COURSE_SUGGESTION));
      dispatch(companionActions.setGreetings(CompanionMode.DEFAULT_GREETINGS));
      dispatch(companionActions.setContext(null));
    }
  }, [location, dispatch]);

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = async () => {
    if (message && message.trim().length > 0) {
      dispatch(
        companionActions.addConversation({
          id: uuidv4(),
          role: 'user',
          type: mode,
          content: message,
          context,
        })
      );

      switch (mode) {
        case CompanionMode.COURSE_SUGGESTION:
          dispatch(
            promptSuggestion({
              conversations: conversations.filter(
                (convo) => convo.type === mode
              ),
              newMessage: message,
            })
          );
          break;
        case CompanionMode.VIDEO_COMPANION:
          dispatch(
            promptVideoCompanion({
              conversations: conversations.filter(
                (convo) => convo.type === mode && convo.context === context
              ),
              newMessage: message,
              context,
            })
          );
          break;
        case CompanionMode.COURSE_OUTLINE:
          dispatch(
            promptCourseOutlineGenerator({
              conversations: conversations.filter(
                (convo) => convo.type === mode && convo.context === context
              ),
              newMessage: message,
              context,
            })
          );
          break;
        case CompanionMode.QUIZ_GENERATOR:
          dispatch(
            promptQuizGenerator({
              conversations: conversations.filter(
                (convo) => convo.type === mode && convo.context === context
              ),
              newMessage: message,
              context,
            })
          );
          break;
        default:
          break;
      }

      setMessage('');
      dispatch(companionActions.setConvertQuizToJson(false));
    }
  };

  const clearConversation = () => {
    dispatch(companionActions.setConversations([]));
    localStorage.removeItem('conversations');
  };

  const handleKeyPressTextInput = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) handleSendMessage();
  };

  const onClickModal = (isOpen) => {
    dispatch(companionActions.setModal(isOpen));
  };

  return (
    <>
      {isDisabled === false && (
        <Box
          as="button"
          pos="fixed"
          bottom="20px"
          right="20px"
          borderRadius="10px"
          bg="#d92e48"
          zIndex="1000"
          p={3}
          boxSize={{ base: '50px', md: '70px' }}
          onClick={() => onClickModal(true)}
        >
          <Image
            objectFit="cover"
            src={require('assets/companion.png')}
            alt="AI Companion"
          />
        </Box>
      )}

      <Drawer
        isOpen={modal}
        placement="right"
        size="sm"
        onClose={() => onClickModal(false)}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader bg="#d92e48">
            <Flex color="white" gap={3}>
              <Center>
                <Image
                  boxSize="30px"
                  objectFit="cover"
                  src={require('assets/companion.png')}
                  alt="AI Companion"
                />
              </Center>
              <Box flex="1">
                <Text> AI Companion</Text>
                <Flex gap={2} align="center">
                  <Box h="10px" w="10px" borderRadius="5px" bg="green.300" />
                  <Text fontSize="xs">Online</Text>
                </Flex>
              </Box>
              <IconButton
                onClick={() => onClickModal(false)}
                size="xs"
                isRound
                variant="ghost"
                colorScheme="black"
                aria-label="Close Companion"
                icon={<CloseIcon />}
              />
            </Flex>
          </DrawerHeader>

          <Flex p={3}>
            {conversations.length > 0 && (
              <Button
                leftIcon={<MdDelete />}
                w="100%"
                size="sm"
                colorScheme="grey"
                variant="outline"
                onClick={() => clearConversation()}
              >
                Clear Conversation
              </Button>
            )}
          </Flex>
          <DrawerBody ref={listRef} bg={useColorModeValue('#F8F9FA', '')}>
            <Stack
              py={5}
              direction="column"
              spacing={{ base: 6, md: 4, lg: 6 }}
            >
              <ChatItem isFromUser={false} message={greetings} />
              {activeConversations.map((conversation) => (
                <ChatItem
                  key={
                    conversation.id ||
                    `${conversation.content}-${conversation.timeSent}`
                  }
                  isFromUser={conversation.role === 'user'}
                  message={conversation.content}
                  mode={conversation.type}
                  timeSent={conversation.timeSent}
                />
              ))}

              {status === ReducerStatus.FAILED && (
                <Box>
                  <Divider colorScheme="red" size="md" />
                  <Center>
                    <Text mt={4} fontWeight="bold" color="tomato">
                      {error}
                    </Text>
                  </Center>
                </Box>
              )}
            </Stack>
          </DrawerBody>

          <Divider />

          <Progress
            display={status === ReducerStatus.LOADING ? 'block' : 'none'}
            size="xs"
            colorScheme="red"
            isIndeterminate
          />

          <Box p={5} boxShadow="lg">
            <Flex>
              <Box w="380px">
                <InputGroup size="md">
                  <Textarea
                    disabled={status === ReducerStatus.LOADING}
                    pr="4.5rem"
                    value={message}
                    onChange={(event) => handleMessageChange(event)}
                    onKeyDown={handleKeyPressTextInput}
                    maxH={250}
                    placeholder="Type your message here..."
                  />
                </InputGroup>
              </Box>
              <Box w="20px" p={2}>
                <IconButton
                  isLoading={status === ReducerStatus.LOADING}
                  onClick={() => handleSendMessage()}
                  h="1.75rem"
                  size="xl"
                  variant="ghost"
                  colorScheme="red"
                  aria-label="Send message"
                  icon={<MdSend />}
                />
              </Box>
            </Flex>
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Companion;
