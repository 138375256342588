import axios from 'axios';

export const LRSQL_URL = process.env.REACT_APP_LRSQL_URL;

export const generateActor = (accountId, courseId, questId, subQuestId) => {
  const actor = JSON.stringify({
    name: [accountId],
    mbox: [`${accountId}_${courseId}_${questId}_${subQuestId}@lrsql-tm.com`],
  });

  return encodeURIComponent(actor);
};

export const generateAuth = () => {
  const lrsqlApiKey = process.env.REACT_APP_LRSQL_API_KEY;
  const lrsqlSecretKey = process.env.REACT_APP_LRSQL_SECRET_KEY;
  return btoa(`${lrsqlApiKey}:${lrsqlSecretKey}`);
};

export const getProgress = async (
  accountId,
  courseId,
  questId,
  subQuestId,
  isRequirePass
) => {
  const agent = {
    objectType: 'Agent',
    mbox: `mailto:${accountId}_${courseId}_${questId}_${subQuestId}@lrsql-tm.com`,
    name: accountId,
  };

  const headers = {
    Authorization: `Basic ${generateAuth()}`,
    'X-Experience-API-Version': '1.0.3',
  };

  try {
    const passedRequest = await axios.get(
      `${LRSQL_URL}xapi/statements?verb=http://adlnet.gov/expapi/verbs/passed&agent=${encodeURIComponent(JSON.stringify(agent))}`,
      { headers }
    );

    if (passedRequest.data.statements.length === 0 && !isRequirePass) {
      const failedRequest = await axios.get(
        `${LRSQL_URL}xapi/statements?verb=http://adlnet.gov/expapi/verbs/failed&agent=${encodeURIComponent(JSON.stringify(agent))}`,
        { headers }
      );

      return failedRequest.data.statements;
    }

    return passedRequest.data.statements;
  } catch {
    return [];
  }
};
