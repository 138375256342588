import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const generateFinalSignature = async (payload, code) => {
  const message = JSON.stringify(payload);

  const hmac = await crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(code),
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign']
  );

  const signature = await crypto.subtle.sign(
    'HMAC',
    hmac,
    new TextEncoder().encode(message)
  );

  const finalSignature = btoa(
    String.fromCharCode(...new Uint8Array(signature))
  );

  return finalSignature;
};

export const fetchProgressionByCourseIdAction = createAsyncThunk(
  'progression/fetchProgressionByCourseIdAction',
  async (courseId) => {
    try {
      const response = await axios.get(`/courses/${courseId}/progression`);
      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const postProgressionAction = createAsyncThunk(
  'progression/postProgressionAction',
  async ({ courseId, questId, subQuestId, code }) => {
    try {
      const payload = {
        CourseId: courseId,
        QuestId: questId,
        SubQuestId: subQuestId,
      };

      const finalSignature = await generateFinalSignature(payload, code);

      const response = await axios.put('/progressions', payload, {
        headers: {
          'Content-Type': 'application/json',
          'x-signature': finalSignature,
        },
      });

      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const postInteractiveSessionAction = createAsyncThunk(
  'progression/postInteractiveSessionAction',
  async ({
    courseId,
    questId,
    subQuestId,
    code,
    isSuccess,
    duration,
    score,
    maxScore,
    passingScore,
  }) => {
    try {
      const payload = {
        CourseId: courseId,
        QuestId: questId,
        SubQuestId: subQuestId,
      };

      const finalSignature = await generateFinalSignature(payload, code);

      const response = await axios.put(
        '/progressions/interactive-content-session',
        {
          ...payload,
          isSuccess,
          duration,
          score,
          passingScore,
          maxScore,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-signature': finalSignature,
          },
        }
      );

      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);
